import { Component } from '@angular/core';

@Component({
  selector: 'fn-footer',
  templateUrl: './fn-footer.component.html',
  styleUrls: ['./fn-footer.component.scss'],
})
export class FnFooterComponent {
  currentYear: number = new Date().getFullYear();
}
