import { Component, Input } from '@angular/core';

@Component({
  selector: 'fn-heading',
  templateUrl: './fn-heading.component.html',
  styleUrls: ['./fn-heading.component.scss']
})
export class FnHeadingComponent {
  @Input() icon?: string;
  @Input() type : 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h1';
  @Input() text: string = '';
}
