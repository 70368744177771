import { Component } from '@angular/core';

@Component({
  selector: 'fn-headline',
  templateUrl: './fn-headline.component.html',
  styleUrls: ['./fn-headline.component.scss'],
})
export class FnHeadlineComponent {
  //headline - h4
  //subheadline - h5, h6
}
