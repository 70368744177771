import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'fn-input',
  templateUrl: './fn-input.component.html',
  styleUrls: ['./fn-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FnInputComponent,
      multi: true,
    },
  ],
})
export class FnInputComponent implements ControlValueAccessor, OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() textarea!: boolean;
  @Input() isValid!: boolean;
  @Input() error!: string;
  @Input() showInfo?: boolean;
  @Input() info?: string;

  value = '';
  touched: boolean = false;

  onChange!: (value: string) => void;
  onTouched!: () => void;

  ngOnInit(): void {}

  onValueChanged($event: any) {
    this.onChange($event);
  }

  writeValue(text: string): void {
    this.value = text;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
    }
    this.touched = true;
  }
}
