import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnInputComponent } from './fn-input/fn-input.component';
import { FnHeadingComponent } from './fn-heading/fn-heading.component';
import { FnButtonComponent } from './fn-button/fn-button.component';
import { FnSelectComponent } from './fn-select/fn-select.component';
import { FnHeadlineComponent } from './fn-headline/fn-headline.component';
import { FnRadioComponent } from './fn-radio/fn-radio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FnHeaderComponent } from './fn-header/fn-header.component';
import { FnFooterComponent } from './fn-footer/fn-footer.component';
import { FnLinkComponent } from './fn-link/fn-link.component';
import { RouterModule } from '@angular/router';
import { FnErrorShowComponent } from './fn-error-show/fn-error-show.component';
import { FnIconComponent } from './fn-icon/fn-icon.component';



@NgModule({
  declarations: [
    FnInputComponent,
    FnHeadingComponent,
    FnButtonComponent,
    FnSelectComponent,
    FnRadioComponent,
    FnHeadlineComponent,
    FnHeaderComponent,
    FnFooterComponent,
    FnLinkComponent,
    FnErrorShowComponent,
    FnIconComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    FnInputComponent,
    FnHeadingComponent,
    FnButtonComponent,
    FnSelectComponent,
    FnRadioComponent,
    FnHeadlineComponent,
    FnHeaderComponent,
    FnFooterComponent,
    FnLinkComponent,
    FnErrorShowComponent
  ],
})
export class FnUIModule {}
