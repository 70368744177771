<div class="container mt-5 mb-5">
  <div class="col-10 m-auto d-flex flex-column">
    <div class="text-center">
      <fn-heading text="Impressum"/>
    </div>
    <h3 class="mt-5"><strong>Verantwortlich für die Homepage</strong></h3>
    <address>
      Fressnapf Tiernahrung GmbH<br>
      Westpreußenstraße 32-38<br>
      47809 Krefeld<br>
      E-Mail: <fn-link [link]="'mailto:info@fressnapf.de'">info@fressnapf.de</fn-link><br>
    </address>
    <h3><strong>Kontakt</strong></h3>
    <address>
      Telefonnummer:  02151- 51910
    </address>
    <h3><strong>Geschäftsführer</strong></h3>
    <p>Heiko Bücker, Jochen Huppert, Christian Kümmel, Dr. Johannes Steegmann</p>
    <h3><strong>Sitz und Registerrecht</strong></h3>
    <address>
      Sitz der Gesellschaft: Krefeld<br>
      Registergericht: Amtsgericht Krefeld<br>
      Handelregisternummer: HRB 5030<br>
      Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE 120591970<br>
    </address>
    <h3><strong>Online-Streitbeilegung</strong></h3>
    <p class="text-justify">Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <fn-link link="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</fn-link> finden. Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.</p>
  </div>
</div>
