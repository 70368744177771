import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AbortCancellationComponent } from "./features/cancel-subcription-mgnt/abort-cancellation/abort-cancellation.component";
import { CancelSubscriptionComponent } from "./features/cancel-subcription-mgnt/cancel-subscription/cancel-subscription.component";
import { CancellationConfirmationComponent } from "./features/cancel-subcription-mgnt/cancellation-confirmation/cancellation-confirmation.component";
import { ConfirmEmailSubscriptionComponent } from "./features/cancel-subcription-mgnt/confirm-email-subscription/confirm-email-subscription.component";
import { DataPrivacyNoticesComponent } from "./features/legal-pages/data-privacy-notices/data-privacy-notices.component";
import { ImprintComponent } from "./features/legal-pages/imprint/imprint.component";


const routes: Routes = [
  { path: 'impressum', component: ImprintComponent },
  { path: 'datenschutzhinweise', component: DataPrivacyNoticesComponent },
  { path: 'confirm-email', component: ConfirmEmailSubscriptionComponent },
  { path: 'abort-cancellation/:aboId', component: AbortCancellationComponent },
  { path: 'confirm-cancellation/:aboId', component: CancellationConfirmationComponent },
  { path: '', pathMatch: 'full', component: CancelSubscriptionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: "enabled"
    }
    )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
