<div class="d-flex flex-column min-vh-100">
  <header>
    <fn-header></fn-header>
  </header>
  <main class="min-vw-100">
    <router-outlet></router-outlet>
  </main>
  <footer class="mt-auto">
    <fn-footer></fn-footer>
  </footer>
</div>





