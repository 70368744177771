import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fn-select',
  templateUrl: './fn-select.component.html',
  styleUrls: ['./fn-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FnSelectComponent,
      multi: true,
    },
  ],
})
export class FnSelectComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() options: string[] = [];
  currentOption = '';
  active: boolean = false;

  onChange!: (currentOption: string) => void;
  onTouched!: () => void;

  ngOnInit(): void {}

  onSelect(option: string) {
    this.currentOption = option;
    this.onChange(this.currentOption);
    this.active = !this.active;
  }

  writeValue(option: string): void {
    this.currentOption = option;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
