import { Component } from '@angular/core';

@Component({
  selector: 'app-data-privacy-notices',
  templateUrl: './data-privacy-notices.component.html',
  styleUrls: ['./data-privacy-notices.component.scss']
})
export class DataPrivacyNoticesComponent {

}
