import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material/material.module';
import { CancelSubcriptionMgntModule } from "./features/cancel-subcription-mgnt/cancel-subcription-mgnt.module";
import { FnUIModule } from './shared/modules/fn-ui/fn-ui.module';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from 'api/generated/backend/api.module';
import { environment } from 'src/environments/environment';
import { LegalPagesModule } from './features/legal-pages/legal-pages.module';

@NgModule({
  declarations: [AppComponent],
  providers: [],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    CancelSubcriptionMgntModule,
    LegalPagesModule,
    FnUIModule,
    AppRoutingModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
  ],
})
export class AppModule {}
