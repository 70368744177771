<div class="fn-select">
      <label class="fn-select__label">{{label}}</label>
      <input type="text" [placeholder]="placeholder" [(ngModel)]="currentOption" (ngModelChange)="onChange($event)" (blur)="onTouched" hidden>
      <div class="fn-select__box" [ngClass]="{'fn-select__box-active': active}" (click)="active=!active">{{currentOption || placeholder}}
        <span *ngIf="active else inactive"  class="material-icons-round ml-auto">&#xe5ce;</span>
        <ng-template #inactive>
          <span class="material-icons-round ml-auto">&#xe5cf;</span>
        </ng-template>
      </div>
      <ul class="fn-select__list" [ngClass]="{'fn-select__list-active' : active}">
        <li *ngFor="let option of options" (click)="onSelect(option)">{{option}}</li>
      </ul>
</div>
