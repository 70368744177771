import { Component } from '@angular/core';

@Component({
  selector: 'fn-header',
  templateUrl: './fn-header.component.html',
  styleUrls: ['./fn-header.component.scss']
})
export class FnHeaderComponent {

}
