import { Component, Input } from '@angular/core';

@Component({
  selector: 'fn-button',
  templateUrl: './fn-button.component.html',
  styleUrls: ['./fn-button.component.scss']
})
export class FnButtonComponent {
  @Input() label!: string;
  @Input() submit!: boolean;
  @Input() color: string = 'primary'; // primary for white over grenn and secondary green over white
  @Input() disabled!: boolean;
}
