/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AbortCancellationRequest } from '../models/abort-cancellation-request';
import { AbortCancellationResponse } from '../models/abort-cancellation-response';
import { CancellationRequest } from '../models/cancellation-request';
import { CancellationResponse } from '../models/cancellation-response';
import { ConfirmCancellationRequest } from '../models/confirm-cancellation-request';
import { ConfirmCancellationResponse } from '../models/confirm-cancellation-response';


/**
 * Cancel a subscription item.
 */
@Injectable({
  providedIn: 'root',
})
export class CancellationManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation writeSubscriptionItemCancellation
   */
  static readonly WriteSubscriptionItemCancellationPath = '/subscription/unsubscribe';

  /**
   * Cancel an active subscription item to product.
   *
   * Every subscription item is bound to a product or service the customer subscribed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `writeSubscriptionItemCancellation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  writeSubscriptionItemCancellation$Response(params: {

    /**
     * Partially update the root subscription. The root subscription stays active until the customer requests to delete any of his informations. The customer only cancel the subscription item which is bound to a specific product. Please refer to the documentation to see the difference between subscription root and subscription item.
     */
    body: CancellationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CancellationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CancellationManagementService.WriteSubscriptionItemCancellationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CancellationResponse>;
      })
    );
  }

  /**
   * Cancel an active subscription item to product.
   *
   * Every subscription item is bound to a product or service the customer subscribed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `writeSubscriptionItemCancellation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  writeSubscriptionItemCancellation(params: {

    /**
     * Partially update the root subscription. The root subscription stays active until the customer requests to delete any of his informations. The customer only cancel the subscription item which is bound to a specific product. Please refer to the documentation to see the difference between subscription root and subscription item.
     */
    body: CancellationRequest
  },
  context?: HttpContext

): Observable<CancellationResponse> {

    return this.writeSubscriptionItemCancellation$Response(params,context).pipe(
      map((r: StrictHttpResponse<CancellationResponse>) => r.body as CancellationResponse)
    );
  }

  /**
   * Path part for operation abortSubscriptionItemCancellation
   */
  static readonly AbortSubscriptionItemCancellationPath = '/subscription/abort-cancellation';

  /**
   * Abort a pending cancellation.
   *
   * A subscription item can have the status RUNNINg or CANCELLED
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `abortSubscriptionItemCancellation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  abortSubscriptionItemCancellation$Response(params: {

    /**
     * Partially update the subscription item. The root subscription stays active until the customer requests to delete any of his informations. The cancellation status becomes ABORTED and the subscription item status stays in the  RUNNING state
     */
    body: AbortCancellationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AbortCancellationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CancellationManagementService.AbortSubscriptionItemCancellationPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AbortCancellationResponse>;
      })
    );
  }

  /**
   * Abort a pending cancellation.
   *
   * A subscription item can have the status RUNNINg or CANCELLED
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `abortSubscriptionItemCancellation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  abortSubscriptionItemCancellation(params: {

    /**
     * Partially update the subscription item. The root subscription stays active until the customer requests to delete any of his informations. The cancellation status becomes ABORTED and the subscription item status stays in the  RUNNING state
     */
    body: AbortCancellationRequest
  },
  context?: HttpContext

): Observable<AbortCancellationResponse> {

    return this.abortSubscriptionItemCancellation$Response(params,context).pipe(
      map((r: StrictHttpResponse<AbortCancellationResponse>) => r.body as AbortCancellationResponse)
    );
  }

  /**
   * Path part for operation confirmSubscriptionItemCancellation
   */
  static readonly ConfirmSubscriptionItemCancellationPath = '/subscription/confirm-cancellation';

  /**
   * Confirm a pending cancellation.
   *
   * A subscription item can have the status RUNNING or CANCELLED
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmSubscriptionItemCancellation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmSubscriptionItemCancellation$Response(params: {

    /**
     * Partially update the subscription item. The root subscription stays active until the customer requests to delete any of his informations. The cancellation status becomes CONFIRMED and the subscription item status switched to the  CANCELLED state
     */
    body: ConfirmCancellationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConfirmCancellationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CancellationManagementService.ConfirmSubscriptionItemCancellationPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmCancellationResponse>;
      })
    );
  }

  /**
   * Confirm a pending cancellation.
   *
   * A subscription item can have the status RUNNING or CANCELLED
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmSubscriptionItemCancellation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmSubscriptionItemCancellation(params: {

    /**
     * Partially update the subscription item. The root subscription stays active until the customer requests to delete any of his informations. The cancellation status becomes CONFIRMED and the subscription item status switched to the  CANCELLED state
     */
    body: ConfirmCancellationRequest
  },
  context?: HttpContext

): Observable<ConfirmCancellationResponse> {

    return this.confirmSubscriptionItemCancellation$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConfirmCancellationResponse>) => r.body as ConfirmCancellationResponse)
    );
  }

}
