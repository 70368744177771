import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbortCancellationRequest } from 'api/generated/backend/models';
import { CancellationManagementService } from 'api/generated/backend/services';
import { isEmpty } from 'rxjs';

@Component({
  selector: 'app-abort-cancellation',
  templateUrl: './abort-cancellation.component.html',
  styleUrls: ['./abort-cancellation.component.scss'],
})
export class AbortCancellationComponent implements OnInit{
  aboId!: any;

  constructor(private route: ActivatedRoute, private router: Router, private cancellationManagementService: CancellationManagementService) {}


  ngOnInit(): void {
    this.aboId = this.route.snapshot.paramMap.get('aboId');
    console.log(this.aboId);

    if(this.aboId == undefined || this.aboId == null || this.aboId.isEmpty || Number.isNaN(this.aboId)) {
        this.router.navigateByUrl('/');
    }else {
      const abortCancellationRequest = {
        aboId: this.aboId
      }
      console.log(abortCancellationRequest);
      this.cancellationManagementService.abortSubscriptionItemCancellation({body: abortCancellationRequest}).subscribe(
        next => {},
        error => {console.log(error.error)}
      );
    }
  }


}
