<div class="fn-input">
    <label class="fn-input__label">{{label}} <fn-icon *ngIf="showInfo" symbol="info" [text]="info"></fn-icon></label>
    <span *ngIf="textarea" class="fn-input__icon">
      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.08649 0L0 8.25L0.735135 9L8.82163 0.75L8.08649 0ZM8.26454 4.06055L4.58887 7.81055L5.324 8.56055L8.99968 4.81055L8.26454 4.06055Z" fill="#B2B2B2"/>
      </svg>
    </span>
    <textarea *ngIf="textarea else no_textarea" class="fn-input__box" rows="4" cols="50" [placeholder]="placeholder" [(ngModel)]="value" (ngModelChange)="onValueChanged($event)" (blur)="markAsTouched()" [ngClass]="{'fn-valid': touched && isValid,'fn-invalid': touched && !isValid}"></textarea>
    <ng-template #no_textarea>
        <input class="fn-input__box" type="text" [placeholder]="placeholder" [(ngModel)]="value" (ngModelChange)="onValueChanged($event)" (blur)="markAsTouched()" [ngClass]="{'fn-valid': touched && isValid,'fn-invalid': touched && !isValid}">
      </ng-template>
    <fn-error-show [error]="error" [showError]="touched && !isValid"></fn-error-show>
</div>
