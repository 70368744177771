import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CancellationRequest } from 'api/generated/backend/models';
import { CancellationManagementService } from 'api/generated/backend/services';
import { FormsDataService } from 'src/app/shared/services/frontend/forms-data.service';

@Component({
  selector: 'app-confirm-email-subscription',
  templateUrl: './confirm-email-subscription.component.html',
  styleUrls: ['./confirm-email-subscription.component.scss'],
})
export class ConfirmEmailSubscriptionComponent implements OnInit {
  cancellationForm!: FormGroup;
  constructor(
    private formsDataService: FormsDataService,
    private cancellationManagementService: CancellationManagementService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cancellationForm = this.formsDataService.cancellationForm;
    if(!this.cancellationForm) {
      this.router.navigateByUrl('/');
    }
  }

  onSubmit() {
    const cancelFormValue = this.cancellationForm.value;
    const cancellationRequest: CancellationRequest = {
      abo: cancelFormValue.abo,
      email: cancelFormValue.email,
      firstName: cancelFormValue.firstName,
      lastName: cancelFormValue.lastName,
      reason: cancelFormValue.reason,
      type:
        cancelFormValue.type == 'Ordentliche Kündigung'
          ? 'REGULAR'
          : 'IRREGULAR',
    };

    this.cancellationManagementService
      .writeSubscriptionItemCancellation({ body: cancellationRequest })
      .subscribe(
        (next) => {},
        (error) => {
          console.log(error.error);
        }
      );
  }
}
