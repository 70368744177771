import { Component, Input } from '@angular/core';

@Component({
  selector: 'fn-link',
  templateUrl: './fn-link.component.html',
  styleUrls: ['./fn-link.component.scss']
})
export class FnLinkComponent {
  @Input() link: string = '';

  onClick($event : any){
    if(this.link.startsWith("mailto:")) return;
    if(this.link.startsWith("http")) return;
    $event.preventDefault();
  }
}
