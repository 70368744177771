import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmCancellationRequest, ConfirmCancellationResponse } from 'api/generated/backend/models';
import { CancellationManagementService } from 'api/generated/backend/services';

@Component({
  selector: 'app-cancellation-confirmation',
  templateUrl: './cancellation-confirmation.component.html',
  styleUrls: ['./cancellation-confirmation.component.scss'],
})
export class CancellationConfirmationComponent {
  aboId!: any;
  email!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cancellationManagementService: CancellationManagementService
  ) {}

  ngOnInit(): void {
    this.aboId = this.route.snapshot.paramMap.get('aboId');
    this.email =
      this.route.snapshot.queryParamMap.get('email') ||
      'max.mustermann@mail.com';
    console.log(this.aboId);
    if (
      this.aboId == undefined ||
      this.aboId == null ||
      this.aboId.isEmpty ||
      Number.isNaN(this.aboId)
    ) {
      this.router.navigateByUrl('/');
    } else {
      const confirmCancellationRequest: ConfirmCancellationRequest = {
        aboId: this.aboId,
      };
      this.cancellationManagementService
        .confirmSubscriptionItemCancellation({
          body: confirmCancellationRequest,
        })
        .subscribe(
          (next) => {},
          (error) => {
            console.log(error.error);
          }
        );
    }
  }
}
