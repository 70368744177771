import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fn-radio',
  templateUrl: './fn-radio.component.html',
  styleUrls: ['./fn-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FnRadioComponent,
      multi: true,
    },
  ],
})
export class FnRadioComponent implements ControlValueAccessor {
  @Input() options: string[] = [];
  currentOption = '';

  onChange!: (currentOption: string) => void;
  onTouched!: () => void;

  ngOnInit(): void {}

  onSelect(option: string) {
    this.currentOption = option;
    this.onChange(this.currentOption);
  }

  writeValue(option: string): void {
    this.currentOption = option;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
