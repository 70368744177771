import { Component, Input } from '@angular/core';

@Component({
  selector: 'fn-error-show',
  templateUrl: './fn-error-show.component.html',
  styleUrls: ['./fn-error-show.component.scss']
})
export class FnErrorShowComponent {
  @Input() error!: string;
  @Input() showError!: boolean;
}
