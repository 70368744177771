<div class="fn-heading">
  <span *ngIf="icon==='checked'" class="fn-heading__icon">
    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzOCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNS4wNTUzIDAuNjg4Mzc2QzM1LjQ2NCAwLjE2NjY4NyAzNi4yMTgzIDAuMDc1MTIwNyAzNi43Mzk5IDAuNDgzODU4QzM3LjIyNjkgMC44NjUzNDcgMzcuMzM5MSAxLjU0NzgyIDM3LjAxOTYgMi4wNjEyOEwzNi45NDQ1IDIuMTY4NTVMMTUuMjAwMSAyOS45MjE4QzE0LjI4MyAzMS4wODE0IDEyLjkwNDIgMzEuNzYyMSAxMS40NDE0IDMxLjc3MTVDMTAuMDY0MSAzMS43ODAyIDguNzUzMyAzMS4xOTI1IDcuODIwMjIgMzAuMTU1NEw3LjY0OTc3IDI5Ljk1NThMMS4wNTc3NyAyMS42MDA4QzAuNjQ3MjY0IDIxLjA4MDUgMC43MzYyNjUgMjAuMzI1OSAxLjI1NjU2IDE5LjkxNTRDMS43NDIxNyAxOS41MzIyIDIuNDMxODYgMTkuNTg0MiAyLjg1NTQyIDIwLjAxNTlMMi45NDE5NCAyMC4xMTQyTDkuNTI0NDcgMjguNDU3M0M5Ljk5ODIyIDI5LjA0MjQgMTAuNjk2IDI5LjM3NjIgMTEuNDI2MSAyOS4zNzE2QzEyLjA5NTUgMjkuMzY3MiAxMi43MzQxIDI5LjA3OTIgMTMuMTkzNSAyOC41NzlMMTMuMzE0MyAyOC40Mzc0TDM1LjA1NTMgMC42ODgzNzZaIiBmaWxsPSIjMTk2NDI4Ii8+Cjwvc3ZnPgo=" alt="Checked icon">
  </span>
  <span *ngIf="icon==='mail'" class="fn-heading__icon">
    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zMS43MTQ4IDAuMDM5NDg5N0g0LjI4NThDMy4wMDgwNyAwLjAzOTQ4OTcgMS44OTA3MSAwLjcyNzAxMSAxLjI4Mzc2IDEuNzUyNDNDMS4yNDU5IDEuODA5MDEgMS4yMTM0NCAxLjg2NzkyIDEuMTg2MzEgMS45Mjg0OUMwLjkzOTI5OSAyLjQwNjkyIDAuNzk5ODA1IDIuOTQ5OSAwLjc5OTgwNSAzLjUyNTQ5VjIyLjQ3NTVDMC43OTk4MDUgMjQuNDAwMiAyLjM2MTAzIDI1Ljk2MDUgNC4yODU4IDI1Ljk2MDVIMzEuNzE0OEMzMy42Mzk1IDI1Ljk2MDUgMzUuMTk5OCAyNC40MDAyIDM1LjE5OTggMjIuNDc1NVYzLjUyNTQ5QzM1LjE5OTggMi45NTA5NiAzNS4wNjA5IDIuNDA4OTIgMzQuODE0OSAxLjkzMTE0QzM0Ljc4NzIgMS44NjkwMyAzNC43NTQgMS44MDg2NCAzNC43MTUxIDEuNzUwNzFDMzQuMTA4MiAwLjcyNjIzNCAzMi45OTE4IDAuMDM5NDg5NyAzMS43MTQ4IDAuMDM5NDg5N1pNMzEuODI1OCAyLjQ0NTFDMzEuNzg5MyAyLjQ0MTM5IDMxLjc1MjMgMi40Mzk0OSAzMS43MTQ4IDIuNDM5NDlINC4yODU4QzQuMjQ4MTQgMi40Mzk0OSA0LjIxMDkyIDIuNDQxNDEgNC4xNzQyMyAyLjQ0NTE1TDE2LjUyMTQgMTIuODYzOUMxNy4zMjc4IDEzLjU0NTEgMTguNDg3MiAxMy41ODI5IDE5LjMzMzIgMTIuOTc3MUwxOS40NzkxIDEyLjg2MzVMMzEuODI1OCAyLjQ0NTFaTTMuMTk5OCA0Ljc2MzE4VjIyLjQ3NTVDMy4xOTk4IDIzLjA3NDQgMy42ODYxOSAyMy41NjA1IDQuMjg1OCAyMy41NjA1SDMxLjcxNDhDMzIuMzE0MSAyMy41NjA1IDMyLjc5OTggMjMuMDc0NyAzMi43OTk4IDIyLjQ3NTVWNC43NjM1MkwyMS4wMjc0IDE0LjY5NzNDMTkuMzM5NSAxNi4xMjMxIDE2Ljg5NjMgMTYuMTcyMyAxNS4xNTY3IDE0Ljg0NTJMMTQuOTczMSAxNC42OTc3TDMuMTk5OCA0Ljc2MzE4WiIgZmlsbD0iIzE5NjQyOCIvPgo8L3N2Zz4K" alt="Mail icon">
  </span>
  <span *ngIf="icon==='human_sad'" class="fn-heading__icon">
    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNyAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjUgMTJDMTQuNSAxMy4xMDQ2IDEzLjYwNDYgMTQgMTIuNSAxNEMxMS4zOTU0IDE0IDEwLjUgMTMuMTA0NiAxMC41IDEyQzEwLjUgMTAuODk1NCAxMS4zOTU0IDEwIDEyLjUgMTBDMTMuNjA0NiAxMCAxNC41IDEwLjg5NTQgMTQuNSAxMloiIGZpbGw9IiMxOTY0MjgiLz4KPHBhdGggZD0iTTI2LjUgMTJDMjYuNSAxMy4xMDQ2IDI1LjYwNDYgMTQgMjQuNSAxNEMyMy4zOTU0IDE0IDIyLjUgMTMuMTA0NiAyMi41IDEyQzIyLjUgMTAuODk1NCAyMy4zOTU0IDEwIDI0LjUgMTBDMjUuNjA0NiAxMCAyNi41IDEwLjg5NTQgMjYuNSAxMloiIGZpbGw9IiMxOTY0MjgiLz4KPHBhdGggZD0iTTI2LjMxNDcgMjUuMDk5NEMyNS43MDc0IDI1LjM2NDggMjUgMjUuMDg3NiAyNC43MzQ2IDI0LjQ4MDNDMjMuNjg0MiAyMi4wNzYyIDIxLjI4NjUgMjAuNCAxOC41IDIwLjRDMTUuNzEzNSAyMC40IDEzLjMxNTggMjIuMDc2MiAxMi4yNjU0IDI0LjQ4MDNDMTIgMjUuMDg3NiAxMS4yOTI2IDI1LjM2NDggMTAuNjg1MyAyNS4wOTk0QzEwLjA3OCAyNC44MzQxIDkuODAwNzkgMjQuMTI2NiAxMC4wNjYxIDIzLjUxOTNDMTEuNDg0OCAyMC4yNzI3IDE0LjcyNTYgMTggMTguNSAxOEMyMi4yNzQ0IDE4IDI1LjUxNTIgMjAuMjcyNyAyNi45MzM5IDIzLjUxOTNDMjcuMTk5MiAyNC4xMjY2IDI2LjkyMiAyNC44MzQxIDI2LjMxNDcgMjUuMDk5NFoiIGZpbGw9IiMxOTY0MjgiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC41IDMzLjZDMjcuMTE1NiAzMy42IDM0LjEgMjYuNjE1NiAzNC4xIDE4QzM0LjEgOS4zODQzNiAyNy4xMTU2IDIuNCAxOC41IDIuNEM5Ljg4NDM2IDIuNCAyLjkgOS4zODQzNiAyLjkgMThDMi45IDI2LjYxNTYgOS44ODQzNiAzMy42IDE4LjUgMzMuNlpNMTguNSAzNkMyOC40NDExIDM2IDM2LjUgMjcuOTQxMSAzNi41IDE4QzM2LjUgOC4wNTg4NyAyOC40NDExIDAgMTguNSAwQzguNTU4ODcgMCAwLjUgOC4wNTg4NyAwLjUgMThDMC41IDI3Ljk0MTEgOC41NTg4NyAzNiAxOC41IDM2WiIgZmlsbD0iIzE5NjQyOCIvPgo8L3N2Zz4K" alt="Human icon">
  </span>
  <span *ngIf="icon==='human_glad'" class="fn-heading__icon">
    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDEyQzE0IDEzLjEwNDYgMTMuMTA0NiAxNCAxMiAxNEMxMC44OTU0IDE0IDEwIDEzLjEwNDYgMTAgMTJDMTAgMTAuODk1NCAxMC44OTU0IDEwIDEyIDEwQzEzLjEwNDYgMTAgMTQgMTAuODk1NCAxNCAxMloiIGZpbGw9IiMxOTY0MjgiLz4KPHBhdGggZD0iTTI2IDEyQzI2IDEzLjEwNDYgMjUuMTA0NiAxNCAyNCAxNEMyMi44OTU0IDE0IDIyIDEzLjEwNDYgMjIgMTJDMjIgMTAuODk1NCAyMi44OTU0IDEwIDI0IDEwQzI1LjEwNDYgMTAgMjYgMTAuODk1NCAyNiAxMloiIGZpbGw9IiMxOTY0MjgiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCAzMy42QzI2LjYxNTYgMzMuNiAzMy42IDI2LjYxNTYgMzMuNiAxOEMzMy42IDkuMzg0MzYgMjYuNjE1NiAyLjQgMTggMi40QzkuMzg0MzYgMi40IDIuNCA5LjM4NDM2IDIuNCAxOEMyLjQgMjYuNjE1NiA5LjM4NDM2IDMzLjYgMTggMzMuNlpNMTggMzZDMjcuOTQxMSAzNiAzNiAyNy45NDExIDM2IDE4QzM2IDguMDU4ODcgMjcuOTQxMSAwIDE4IDBDOC4wNTg4NyAwIDAgOC4wNTg4NyAwIDE4QzAgMjcuOTQxMSA4LjA1ODg3IDM2IDE4IDM2WiIgZmlsbD0iIzE5NjQyOCIvPgo8cGF0aCBkPSJNMTAuMTg1MyAyMC4xMDA4QzEwLjc5MjYgMTkuODM1NCAxMS41IDIwLjExMjYgMTEuNzY1NCAyMC43MTk5QzEyLjgxNTggMjMuMTI0IDE1LjIxMzUgMjQuODAwMiAxOCAyNC44MDAyQzIwLjc4NjUgMjQuODAwMiAyMy4xODQyIDIzLjEyNCAyNC4yMzQ2IDIwLjcxOTlDMjQuNSAyMC4xMTI2IDI1LjIwNzQgMTkuODM1NCAyNS44MTQ3IDIwLjEwMDhDMjYuNDIyIDIwLjM2NjEgMjYuNjk5MiAyMS4wNzM2IDI2LjQzMzkgMjEuNjgwOUMyNS4wMTUyIDI0LjkyNzUgMjEuNzc0NCAyNy4yMDAyIDE4IDI3LjIwMDJDMTQuMjI1NiAyNy4yMDAyIDEwLjk4NDggMjQuOTI3NSA5LjU2NjE0IDIxLjY4MDlDOS4zMDA3OCAyMS4wNzM2IDkuNTc3OTggMjAuMzY2MSAxMC4xODUzIDIwLjEwMDhaIiBmaWxsPSIjMTk2NDI4Ii8+Cjwvc3ZnPgo=" alt="Human icon">
  </span>
 <ng-container [ngSwitch]="type">
      <h1 *ngSwitchCase="'h1'" class="fn-heading__title">{{text}}</h1>
      <h2 *ngSwitchCase="'h2'" class="fn-heading__title">{{text}}</h2>
      <h3 *ngSwitchCase="'h3'" class="fn-heading__title">{{text}}</h3>
      <h4 *ngSwitchCase="'h4'" class="fn-heading__title">{{text}}</h4>
      <h5 *ngSwitchCase="'h5'" class="fn-heading__title">{{text}}</h5>
      <h6 *ngSwitchCase="'h6'" class="fn-heading__title">{{text}}</h6>
</ng-container>
</div>

