import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataPrivacyNoticesComponent } from './data-privacy-notices/data-privacy-notices.component';
import { FnUIModule } from 'src/app/shared/modules/fn-ui/fn-ui.module';
import { ImprintComponent } from './imprint/imprint.component';



@NgModule({
  declarations: [
    DataPrivacyNoticesComponent,
    ImprintComponent
  ],
  imports: [
    CommonModule,
    FnUIModule
  ]
})
export class LegalPagesModule { }
