import { Component, Input } from '@angular/core';

@Component({
  selector: 'fn-icon',
  templateUrl: './fn-icon.component.html',
  styleUrls: ['./fn-icon.component.scss']
})
export class FnIconComponent {
  @Input() symbol?: string;
  @Input() text?: string;
}
