<div class="container d-flex justify-content-center">
  <div class="col-6 pt-4">
    <fn-heading icon="mail" text="Kündigungs E-Mail bestätigen"/>
     <p class="mt-5">
      Wir haben dir eine E-Mail an <b>{{cancellationForm.value?.email || 'max.mustermann@email.com'}}</b> gesendet. Bitte klicke innerhalb von 24 Stunden auf den beigefügten Link, um dich zu verifizieren und um die Kündigung abzuschließen.
     </p>
     <!-- some performance issues to be fixed by emarsys before activating email-sending-retry once more -->
     <p class="d-none">
      Falls du keine E-Mail erhalten hast, klicke bitte <fn-link link="/confirm-email" (click)="onSubmit()">hier</fn-link>, um den Versand erneut auszulösen.
    </p>
  </div>
</div>
