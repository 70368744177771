import { NgModule } from '@angular/core';
import { CancelSubscriptionComponent } from './cancel-subscription/cancel-subscription.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FnUIModule } from 'src/app/shared/modules/fn-ui/fn-ui.module';
import { CommonModule } from '@angular/common';
import { ConfirmEmailSubscriptionComponent } from './confirm-email-subscription/confirm-email-subscription.component';
import { CancellationConfirmationComponent } from './cancellation-confirmation/cancellation-confirmation.component';
import { AbortCancellationComponent } from './abort-cancellation/abort-cancellation.component';
import { HttpClientModule } from '@angular/common/http';




@NgModule({
  declarations: [
    CancelSubscriptionComponent,
    ConfirmEmailSubscriptionComponent,
    CancellationConfirmationComponent,
    AbortCancellationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FnUIModule,
    HttpClientModule,
  ],
  exports: [
    CancelSubscriptionComponent,
    ConfirmEmailSubscriptionComponent,
    CancellationConfirmationComponent,
    AbortCancellationComponent,
  ],
})
export class CancelSubcriptionMgntModule {}
