<div class="container mt-5 mb-5">
  <div class="col-sm col-md-10 col-lg-6 m-auto">
    <fn-heading text="Kündigung deines Abonnements"/>
    <form [formGroup] = "cancellationForm" #form="ngForm">
        <div class="mt-5 mb-4">
          <p>Hast Du verschiedene Abos bei Fressnapf oder mehrere Kundenkonten? Dann reiche Deine Kündigungserklärung des jeweiligen Abos bitte einzeln hier ein oder verwalte deine Abos direkt in deinem Kundenkonto.</p>
          <fn-radio [options]="['Ordentliche Kündigung','Außerordentliche Kündigung']" formControlName="type"></fn-radio>
          <fn-headline>Eine außerordentliche Kündigung ist eine Kündigung, bei der die für eine ordentliche Kündigung vorgeschriebene Kündigungsfrist nicht oder nicht vollständig eingehalten wird.</fn-headline>
          <fn-input *ngIf="isIrregularCancel()" label="Grund" placeholder="Bitte gib bei einer außerordentlichen Kündigung deine Begründung an." [textarea]="true" formControlName="reason" [isValid]="isReasonValid()" error="Gib einen gültigen Grund ein"></fn-input>
          <fn-input label="Vorname" error="" placeholder="Max" formControlName="firstName" [isValid]="isFirstNameValid()" error="Gib einen gültigen Vorname ein"></fn-input>
          <fn-input label="Nachname" placeholder="Mustermann" formControlName="lastName" [isValid]="isLastNameValid()" error="Gib einen gültigen Nachname ein"></fn-input>
          <fn-input label="Email" placeholder="max.mustermann@mail.de" [showInfo]="true" [info]="'Bitte nutze hier die von dir bei Fressnapf hinterlegte E-Mailadresse'" formControlName="email" [isValid]="isEmailValid()" error="Gib eine gültige E-Mail Adresse ein"></fn-input>
          <fn-select label="Welches Abo möchtest du kündigen?" formControlName="abo" [options]="products"></fn-select>
          <p>Dein Abonnement endet nach Einreichung der Kündigung zum frühestmöglichen Zeitpunkt.</p>
        </div>
        <fn-button label="Kündigung einreichen" [submit]="true" color="primary" (click)="onSubmit()" [disabled]="!form.valid"></fn-button>
    </form>
  </div>
</div>
