<div class="container mt-5 mb-5">

    <section class="row">
      <div class="col-12">
        <div class="text-center">
          <fn-heading text="Datenschutzhinweise Abo-Kündigungsservice"/>
        </div>
        <p class="mt-5 text-justify">Wir, die Fressnapf Tiernahrung GmbH, möchten Sie mit diesen Datenschutzhinweisen über die Verarbeitung Ihrer personenbezogenen Daten und über Ihre Rechte als betroffene Person bei der Nutzung unseres Abo-Kündigungsservice informieren.</p>
      </div>
    </section>

    <!-- section 1 -->
    <section class="row">
      <div class="col-12">
        <fn-heading type="h2" text="1. Verantwortliche und Datenschutzbeauftragte"/>
        <div class="d-flex flex-column">
          <p class="mt-3 text-justify">Verantwortlicher für die Datenverarbeitungen im Rahmen des Abo-Kündigungsservice ist die:</p>
          <address>
             Fressnapf Tiernahrung GmbH<br>
             Westpreußenstraße 32-38<br>
             47809 Krefeld<br>
             E-Mail: <fn-link [link]="'mailto:info@fressnapf.de'">info@fressnapf.de</fn-link><br>
             Telefonnummer:  02151- 51910<br>
          </address>
        <p class="mt-2 text-justify">Sollten Sie Fragen und Anregungen zum Thema Datenschutz haben, können Sie sich gerne an uns wenden.</p>
        <p>Unsere Datenschutzbeauftragte erreichen Sie unter:</p>
        <address>
          <strong>Alexandra Niesen-Finger</strong><br>
          Fressnapf Holding SE<br>
          Westpreußenstraße 32 – 38, D 47809 Krefeld<br>
          E-Mail: <fn-link [link]="'mailto:privacy@fressnapf.com'">privacy@fressnapf.com</fn-link><br>
        </address>
        </div>
      </div>
    </section>
    <!-- end of section 1 -->
    <!-- section 2 -->
    <section class="row">
      <div class="col-12">
        <fn-heading type="h2" text="2. Gegenstand des Datenschutzes"/>
        <p class="mt-3 text-justify">Gegenstand des Datenschutzes ist der Schutz personenbezogener Daten. Dies sind alle Informationen, die sich auf Sie, als identifizierte oder identifizierbare natürliche Person (sog. betroffene Person), beziehen. Hierunter fallen Angaben wie z.B. Name, Post-Adresse, E-Mail-Adresse oder Telefonnummer, aber auch sonstige Informationen, die Sie uns im Rahmen der Nutzung unseres Abo-Kündigungsservice zur Verfügung stellen. </p>
      </div>
    </section>

    <!-- end of section 2 -->
    <!-- section 3 -->
    <section class="row">
      <div class="col-12">
        <fn-heading type="h2" text="3. Details der Datenverarbeitung"/>
        <p class="mt-3 text-justify">Nachfolgend erhalten Sie einen Überblick darüber, welche personenbezogenen Daten, wir bei Ihnen<sup>1</sup> für welche Zwecke, auf Basis welcher Rechtsgrundlage verarbeiten, und ob wir diese in Einzelfällen noch an Dritte weitergeben sowie ggf. in Drittstaaten übermitteln, wie lange wir diese personenbezogenen Daten speichern und wo wir diese Daten erheben<sup>2</sup>. Sofern Sie uns Ihre personenbezogenen Daten nicht zur Verfügung stellen, obwohl wir diese erheben wollen, um einen Auftrag von Ihnen zu bearbeiten oder einen Vertrag mit Ihnen zu erfüllen oder weil wir gesetzlich dazu verpflichtet sind, müssen wir möglicherweise Ihren Auftrag ablehnen oder können unsere vertraglichen Pflichten Ihnen gegenüber nicht erfüllen.</p>
        <div class="table-responsive">
          <table class="table" style="font-size: 10px !important;">
          <thead>
            <tr>
            <th class="border">Zwecke der Datenverarbeitung</th>
            <th class="border">Datenkategorien</th>
            <th class="border">Rechtsgrundlage/berechtigtes Interesse</th>
            <th class="border">Empfänger der Daten</th>
            <th class="border">Datenübermittlung in Drittstaaten/Garantien</th>
            <th class="border">Speicherdauer</th>
          </tr>
          </thead>
          <tbody>
            <tr>
            <td class="border">
              <p>Aufruf der Website</p>
              <p>Gewährleistung eines reibungslosen Verbindungsaufbaus,</p>
              <p>Gewährleistung einer komfortablen Nutzung unserer Webseite/ Applikation und</p>
              <p>Auswertung der Systemsicherheit und -stabilität]</p>
            </td>
            <td class="border">
              <p>IP-Adresse des anfragenden internetfähigen Gerätes,</p>
              <p>Datum und Uhrzeit des Zugriffs,</p>
              <p>Name und URL der abgerufenen Datei,</p>
              <p>Website/Applikation, von der aus der Zugriff erfolgte (Referrer-URL),</p>
              <p>der von Ihnen verwendete Browser und ggfs. das Betriebssystem Ihres internetfähigen Rechners,</p>
            </td>
            <td class="border">
              <p>Art. 6 Abs. 1 lit. f) DSGVO (unser berechtigtes Interesse an der Sicherheit, Stabilität und Nutzungsmöglichkeit unserer Webseite)</p>
              <p>Art. 6 Abs. 1 lit. b) DSGVO (Begründung, Durchführung und Beendigung des Vertrags mit Ihnen)</p>
            </td>
            <td class="border">
              <p>Microsoft Deutschland GmbH</p>
            </td>
            <td class="border">
              <p>Vereinigte Staaten von Amerika Garantie: Standardvertragsklauseln</p>
            </td>
            <td class="border">
              <p>3 Monate ab Aufruf der Website</p>
            </td>
          </tr>
          <tr>
            <td class="border">
              <p>Gewährleistung einer Verifizierbarkeit der Person </p>
            </td>
            <td class="border">
              <p>Vorname, Nachname, E-Mailadresse</p>
            </td>
            <td class="border">
              <p>Art. 6 Abs. 1 lit. b) DSGVO (Begrün-dung, Durchfüh-rung und Beendi-gung des Vertrags mit Ihnen)]</p>
            </td>
            <td class="border">
              <p>Microsoft Deutschland GmbH</p>
            </td>
            <td class="border">
              <p>Vereinigte Staaten von Amerika Garantie: Stan-dardvertrags-klauseln</p>
            </td>
            <td class="border">
              <p>3 Monate nachdem Versand des Kündigungsformulars</p>
            </td>
          </tr>
          </tbody>
        </table>
        </div>
      </div>
    </section>
    <!-- end of section 3-->

    <!-- section 4 -->
    <section class="row">
      <div class="col-12">
        <fn-heading type="h2" text="4. Datenübermittlung in Drittländer"/>
        <p>Wir verarbeiten Ihre personenbezogenen Daten ausschließlich im Europäischen Wirtschaftsraum (EWR).</p>
        <p class="mt-3 text-justify">Findet eine Datenübermittlung an Stellen statt, deren Sitz oder deren Ort der Datenverarbeitung außerhalb des EWR oder eines Staates, für den durch einen Beschluss der Europäischen Kommission ein angemessenes Datenschutzniveau festgestellt wurde, gelegen ist, stellen wir vor der Weitergabe sicher, dass die Datenübermittlung durch einen gesetzlichen Erlaubnistatbestand gedeckt ist und Garantien für ein angemessenes Datenschutzniveau in Bezug auf die Datenübermittlung bestehen (z.B. durch die Vereinbarung vertraglicher Gewährleistungen, behördlich anerkannte Regelungen oder verbindliche interne Datenschutzvorschriften beim Empfänger) oder Sie Ihre Einwilligung in die Datenübermittlung erteilt haben.</p>
        <p class="mt-3 text-justify">In diesen Fällen werden wir Sie in dieser Datenschutzinformation über eine entsprechende Datenübermittlung informieren.</p>
        <p class="mt-3 text-justify">Soweit die Datenübermittlung auf Grundlage von Art. 46, 47 oder 49 Abs. 1 Unterabsatz 2 DSGVO erfolgt, können Sie bei uns eine Kopie der Garantien für das Bestehen eines angemessenen Datenschutzniveaus in Bezug auf die Datenübermittlung oder einen Hinweis auf die Verfügbarkeit einer Kopie der Garantien erhalten.</p>
        <p>Für detaillierte Informationen über Datenübermittlungen in Drittländer und eine entsprechende Kopie der Garantien, können Sie sich jederzeit an </p>
        <address>
          <strong>Alexandra Niesen-Finger</strong><br>
          Fressnapf Holding SE<br>
          Westpreußenstraße 32 – 38, D 47809 Krefeld<br>
          E-Mail: <fn-link [link]="'mailto:privacy@fressnapf.com'">privacy@fressnapf.com</fn-link><br>
        </address>
        <p>wenden.</p>
      </div>
    </section>

    <!-- end of section 4 -->
    <!-- section 5 -->
    <div class="d-flex">
        <fn-heading type="h2" text="5. Speicherdauer"/><sup class="mt-3">3</sup>
    </div>
    <p class="mt-3 text-justify">Wir speichern Ihre personenbezogenen Daten, solange dies für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten erforderlich ist.</p>
    <p class="mt-3 text-justify">Sind Ihre personenbezogenen Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten nicht mehr erforderlich, werden diese gelöscht, es sei denn, deren – befristete – Weiterverarbeitung ist erforderlich zu folgenden Zwecken:</p>
    <ul>
      <li>Erfüllung gesetzlicher, insbesondere handels- und steuerrechtlicher Aufbewahrungspflichten, die sich z.B. aus dem Handelsgesetzbuch (HGB) oder der Abgabenordnung (AO) ergeben können. Die dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen in der Regel zwei bis zehn Jahre.</li>
      <li>Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften. Nach den §§ 195 ff des Bürgerlichen Gesetzbuches (BGB) können diese Verjährungsfristen bis zu 30 Jahre betragen, wobei die regelmäßige Verjährungsfrist 3 Jahre beträgt.]</li>
    </ul>
    <!-- end of section 5 -->
    <!-- section 6 -->
    <section class="row">
      <div class="col-12">
        <fn-heading type="h2" text="6. Betroffenenrechte"/>
        <p class="mt-3 text-justify">Sie haben jederzeit das Recht, im gesetzlich vorgesehenen Rahmen,</p>
        <ul>
          <li>gem. Art. 7 Abs. 3 DSGVO ihre einmal erteilte Einwilligung zu <strong>widerrufen</strong>;</li>
          <li>gem. Art. 15 DSGVO <strong>Auskunft</strong> über die Sie betreffenden verarbeiteten personenbezogenen Daten zu verlangen;</li>
          <li>gem. Art. 16 DSGVO die <strong>Berichtigung</strong> unrichtiger oder Vervollständigung der uns über Sie gespeicherten personenbezogenen Daten zu verlangen;</li>
          <li>gem. Art. 17 DSGVO die <strong>Löschung</strong> der gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
          <li>gem. Art. 18 DSGVO die <strong>Einschränkung</strong> der Verarbeitung der personenbezogenen Daten zu verlangen;</li>
          <li>gem. Art. 20 DSGVO die personenbezogenen Daten, die Sie bereitgestellt haben, <strong>in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu</strong> verlangen und</li>
          <li>gem. Art. 77 DSGVO sich bei einer <strong>Aufsichtsbehörde zu beschweren</strong>. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder unseres Unternehmenssitzes wenden.</li>
          <li><strong>Widerspruch</strong> gem. 21 DSGVO</li>
        </ul>
        <p class="border p-3 col-11 col-md-6 ml-4"><small>Art. 21 DSGVO, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Datenverarbeitung durch uns zu widersprechen, soweit diese auf der Wahrung „berechtigter Interessen“ gem. Art. 6 Abs. 1 lit. f DSGVO beruht. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen, werden wir die Verarbeitung einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Weiterverarbeitung nachweisen, welche Ihre Rechte und Interessen überwiegen.</small></p>
        <p>Zur Geltendmachung Ihrer Rechte können Sie sich jederzeit an:</p>
        <address>
          <strong>Alexandra Niesen-Finger</strong><br>
          Fressnapf Holding SE<br>
          Westpreußenstraße 32 – 38, D 47809 Krefeld<br>
          E-Mail: <fn-link [link]="'mailto:privacy@fressnapf.com'">privacy@fressnapf.com</fn-link><br>
        </address>
        <p>wenden.</p>
        <fn-heading type="h2" text="7. Änderungen dieser Datenschutzhinweise"/>
        <p class="mt-3 text-justify">Der Stand dieser Datenschutzhinweise wird durch die Datumsangabe (unten) kenntlich gemacht. Wir behalten uns das Recht vor, diese Datenschutzhinweise jederzeit mit Wirkung für die Zukunft zu ändern.</p>
        <p>Stand dieser Datenschutzhinweise: [April / 2023]</p>
      </div>
    </section>
    <!-- end of section 6-->
    <!-- section footnotes-->
    <section class="row">
      <div class="col-12">
        <div class="mt-5" style="height: 1px; background-color: black; width: 23%;"></div>
        <ul  style="list-style: none;font-size: 12px!important;">
          <li class="m-1"><sup>1</sup><strong>Erläuterung:</strong> Sofern Sie die personenbezogenen Daten nur bei der betroffenen Person erheben, können Sie die Spalte „Erhebung der Daten“ in der Tabelle unten löschen und den Textbaustein „bei Ihnen“ im Text belassen. Sofern Sie die personenbezogenen Daten nicht nur bei der betroffenen Person erheben, löschen Sie bitte diesen Textbaustein „bei Ihnen“ und befüllen Sie unten die Spalte „Erhebung der Daten“ mit der jeweiligen Quelle.</li>
          <li  class="m-1"><sup>2</sup><strong>Erläuterung:</strong> Sofern Sie eine der Spalten zu Datenübermittlungen in Drittstaaten, zur Speicherdauer und / oder zur Erhebung der Daten aus der Tabelle löschen, entfernen Sie den zugehörigen Hinweis auch aus diesem Textbaustein.</li>
          <li class="m-1"><sup>3</sup><strong>Erläuterung:</strong> Sofern Sie die Spalte zur Speicherdauer in Ziffer 3 nicht gelöscht, sondern befüllt haben, ist Ziffer 6 zu löschen.</li>
        </ul>
       </div>
    </section>
    <!-- end of section footnotes-->
</div>
